import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from '../context/modalContext';
import Layout from "../components/layout";
import SideNav from "../components/sideNav";
import provenPath from "../img/proven.svg";
import qualityPath from "../img/quality.svg";
import speedPath from "../img/speed.svg";
import foundersPath from "../img/founders.svg";
import fundingPath from "../img/funding.svg";
import futurePath from "../img/future.svg";
import LogoBlack from "../img/LogoBlack.svg";
import { Link, graphql } from "gatsby";
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import SEO from '../components/seo'


export default ({ data }) => {

	// chat modal toggle
	const { onHide } = useContext(ModalContext);

	// sidenav scrolling logic

	const [currentSection, setCurrentSection] = useState(1);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});

	const handleScroll = () => {
		if (typeof document !== `undefined`) {
			var sec2 = document.getElementById('techforequity');
			var sec3 = document.getElementById('cashinvestment');
			var sec4 = document.getElementById('portfolio');
		}
		if (sec2) {
			var secpos2 = sec2.offsetTop;
		}
		if (sec3) {
			var secpos3 = sec3.offsetTop;
		}
		if (sec4) {
			var secpos4 = sec4.offsetTop;
		}

		if (window.scrollY === 0) {
			setCurrentSection(1);
		}
		else if (window.scrollY + 50 > secpos2 && window.scrollY < secpos3) {
			setCurrentSection(2);
		}
		else if (window.scrollY > secpos3 && window.scrollY < secpos4) {
			setCurrentSection(3);
		}
		else if (window.scrollY + 100 > secpos4) {
			setCurrentSection(4);
		}
		else {
			setCurrentSection(1);
		}

	}

	return (
		<Layout>
			<SEO title="Home" image="/img/HomePage.png" description="We help MENA startups scale faster by offering our full stack tech team as a service and through cash investment" />
			<SideNav currentSection={currentSection} />
			<div className="section1" id="whatwedo">
				<div className="row">
					<div className="col-md-12 col-lg-6 order-lg-2">
						<Img fluid={data.page1img1.childImageSharp.fluid} className="img1" />
					</div>
					<div className="col-md-12 col-lg-6 order-lg-1">
						<Fade left distance={'5%'} duration={2500}>
  	           <p className="para1 font-sz-75 font-f6" data-1>{data.fennecJson.homePage.whatWeDo.data1}</p>
						</Fade>
					</div>
				</div>
				<Fade left distance={'20%'} duration={1500}>
					<p className="para2 font-sz-28 font-f4" data-2>{data.fennecJson.homePage.whatWeDo.data2}</p>
				</Fade>
				<Fade left cascade distance={'20%'}>
					<div className="row buttonGroup">
						<Link className="col-7 col-sm-6" to="/#techforequity" data-scroll><button className="section1Button font-sz-18 font-f5">TECH FOR EQUITY</button></Link>
						<Link className="col-7 col-sm-6" to="/#cashinvestment" data-scroll><button className="section1Button font-sz-18 font-f5">CASH INVESTMENT</button></Link>
					</div>
				</Fade>
			</div>
			<div className="section2" id="techforequity">
				<div className="row techForEquityRow">
					<Fade right cascade distance={'30%'}>
						<div className="col-md-12 col-lg-4 order-lg-2 paraGroup2">
							<h1 className="para21 font-f5 font-sz-28" data-3>{data.fennecJson.homePage.techForEquity.data3}</h1>
							<h2 className="para22 font-f4 font-sz-44" data-4>{data.fennecJson.homePage.techForEquity.data4}</h2>
							<p className="whySpend font-more font-sz-20" data-5 id="whatIsIt">{data.fennecJson.homePage.techForEquity.data5}</p>
						</div>
					</Fade>
					<div className="col-md-12 col-lg-5 order-lg-1">
						<Img fluid={data.page1img2.childImageSharp.fluid} className="img2" />
					</div>
				</div>
				<div className="para24Group">
					<Fade>
						<h2 className="para24 font-f4 font-sz-44" data-6>{data.fennecJson.homePage.techForEquity.data6}</h2>
						<p className="para25 font-more font-sz-20" data-7>{data.fennecJson.homePage.techForEquity.data7}</p>
					</Fade>
				</div>
				<h2 className="para26 font-sz-35 font-f5" data-8 >{data.fennecJson.homePage.techForEquity.data8}</h2>
				<div className="row justify-content-center circleGroup text-center">
					<Fade cascade duration={2000}>
						<div className="col-6 col-md-3 col-lg-3 pt-3 text-center">
							<div className="circle circle-product">
								<p className="para27 font-f5 font-sz-20">PRODUCT STRATEGY</p>
							</div>
						</div>
						<div className="col-6 col-md-3 col-lg-3 pt-3 text-center">
							<div className="circle circle-design">
								<p className="para27 font-f5 font-sz-20">DESIGN</p>
							</div>
						</div>
						<div className="col-6 col-md-3 col-lg-3 pt-3 text-center">
							<div className="circle circle-development">
								<p className="para27 font-f5 font-sz-20">DEVELOPMENT</p>
							</div>
						</div>
						<div className="col-6 col-md-3 col-lg-3 pt-3 text-center">
							<div className="circle circle-growth">
								<p className="para27 font-f5 font-sz-20" id="whyChooseUs">GROWTH</p>
							</div>
						</div>
					</Fade>
				</div>
				<h2 className="para28 font-f5 font-sz-35" data-9 >{data.fennecJson.homePage.techForEquity.data9}</h2>
				<div className="row justify-content-center">
					<Fade>
						<div className="col-12 col-lg-3 text-center">
							<img src={provenPath} alt="" className="whyChooseUsImgFirst" />
							<h3 className="para29 font-f5 font-sz-22" data-10 >{data.fennecJson.homePage.techForEquity.data10}</h3>
							<p className="para211 font-more font-sz-16" data-11 >{data.fennecJson.homePage.techForEquity.data11}</p>
						</div>
					</Fade>
					<Fade delay={200}>
						<div className="col-12 col-lg-3 text-center">
							<img src={qualityPath} alt="" className="whyChooseUsImg" />
							<h3 className="para29 font-f5 font-sz-22" data-12 >{data.fennecJson.homePage.techForEquity.data12}</h3>
							<p className="para211 font-more font-sz-16" data-13 >{data.fennecJson.homePage.techForEquity.data13}</p>
						</div>
					</Fade>
					<Fade delay={400}>
						<div className="col-12 col-lg-3 text-center">
							<img src={speedPath} alt="" className="whyChooseUsImg" />
							<h3 className="para29 font-f5 font-sz-22" data-14 >{data.fennecJson.homePage.techForEquity.data14}</h3>
							<p className="para211 font-more font-sz-16" data-15 >{data.fennecJson.homePage.techForEquity.data15}</p>
						</div>
					</Fade>
				</div>
				<div className="wantToPartner">
					<h2 className="para212 font-f5 font-sz-35" data-16 >{data.fennecJson.homePage.techForEquity.data16}</h2>
					<button className="button212 font-f6 font-sz-16" onClick={() => onHide(true)}>GET IN TOUCH</button>
				</div>
				<div className="annotationSection" id="cashinvestment">
					<Img className="imgAnnotation" fluid={data.cashInvestmentImg.childImageSharp.fluid} />
					<Fade bottom cascade>
						<div className="paraGroup3">
							<h1 className="cashInvest font-f5 font-sz-28 pb-2" data-17 >{data.fennecJson.homePage.cashInvestment.data17}</h1>
							<h3 className="wePutOur font-f4 font-sz-44 pb-2" data-18 >{data.fennecJson.homePage.cashInvestment.data18}</h3>
							<p className="ifYou font-more font-sz-20" data-19 >{data.fennecJson.homePage.cashInvestment.data19}</p>
						</div>
					</Fade>
					<div className="HowWeWorkSection" id="howWeWork">
						<Fade >
							<div className="row para216Group">
								<h1 className="para216 font-f4 font-sz-44" data-20 >{data.fennecJson.homePage.cashInvestment.data20}</h1>
								<p className="para217 font-more font-sz-20" id="whatWeFocusOn" data-21 >{data.fennecJson.homePage.cashInvestment.data21}</p>
							</div>
						</Fade>
						<h2 className="para218 font-f5 font-sz-35" data-22 >{data.fennecJson.homePage.cashInvestment.data22}</h2>
						<div className="row justify-content-center founderRow">
							<Fade>
								<div className="col-12 col-lg-4 text-lg-left text-center">
									<img src={foundersPath} alt="" className="foundersImg" />
									<h3 className="para29 font-f5 font-sz-22" data-23 >{data.fennecJson.homePage.cashInvestment.data23}</h3>
									<p className="para2111 font-more font-sz-16" data-24 >{data.fennecJson.homePage.cashInvestment.data24}</p>
								</div>
							</Fade>
							<Fade delay={200}>
								<div className="col-12 col-lg-4 text-lg-left text-center">
									<img src={fundingPath} alt="" className="foundersImg" />
									<h3 className="para29 font-f5 font-sz-22" data-25 >{data.fennecJson.homePage.cashInvestment.data25}</h3>
									<p className="para2111 font-more font-sz-16" data-26 >{data.fennecJson.homePage.cashInvestment.data26}</p>
								</div>
							</Fade>
							<Fade delay={400}>
								<div className="col-12 col-lg-4 text-lg-left text-center">
									<img src={futurePath} alt="" className="foundersImg" />
									<h3 className="para29 font-f5 font-sz-22" data-27 >{data.fennecJson.homePage.cashInvestment.data27}</h3>
									<p className="para2111 font-more  font-sz-16" data-28 >{data.fennecJson.homePage.cashInvestment.data28}</p>
									<h3 className="para2211 font-f5 font-sz-20" data-29 >{data.fennecJson.homePage.cashInvestment.data29}</h3>
								</div>
							</Fade>
						</div>
					</div>
					<div className="tellusgroup">
						<h2 className="para219 font-f5 font-sz-35" data-30 >{data.fennecJson.homePage.cashInvestment.data30}</h2>
						<Link to="/howItWorks/"><button className="button219 font-f6 font-sz-16">HOW IT WORKS</button></Link>
					</div>
				</div>
			</div>
			<div className="section3" id="portfolio">
				<div className="para3Group text-center">
					<Fade bottom cascade>
						<div className="row">
							<h1 className="para31 font-f4 font-sz-60" data-31 >{data.fennecJson.homePage.portfolio.data31}</h1>
						</div>
						<div className="row">
							<h3 className="para32 font-f5 font-sz-35" data-32 >{data.fennecJson.homePage.portfolio.data32}</h3>
						</div>
						<div className="row">
							<p className="para33 font-more font-sz-20" data-33 >{data.fennecJson.homePage.portfolio.data33}</p>
						</div>
					</Fade>
				</div>
				<div className="row justify-content-center imagesGrouper">
					<Fade>
						<div className="col-12 col-md-4 col-xl-6 imgCol1  text-center ">
							<a href="http://www.typoman.net/" target="_blank" rel="noopener noreferrer">
								<Img className="section3img mb-sm-0 mb-md-4" fluid={data.typoman.childImageSharp.fluid} />
							</a>
							<a href="https://www.snappcard.com/home" target="_blank" rel="noopener noreferrer">
								<Img className="section3img" fluid={data.snappCard.childImageSharp.fluid} />
							</a>
						</div>
						<div className="col-12 col-md-4 col-xl-6 imgCol2  text-center ">
							<a href="https://www.bigitec.com/" target="_blank" rel="noopener noreferrer">
								<Img className="section3img mb-sm-0 mb-md-4" fluid={data.muslim.childImageSharp.fluid} />
							</a>
							<a href="https://falcon9.io/" target="_blank" rel="noopener noreferrer">
								<Img className="section3img" fluid={data.falcon.childImageSharp.fluid} />
							</a>
						</div>
					</Fade>
				</div>
			</div>
			<div className="section4 row justify-content-center">
				<div className="col-12 col-xl-4 d-none d-xl-block">
					<Img className="leftImg5" fluid={data.bigLamp.childImageSharp.fluid} />
				</div>
				<div className="col-12 col-xl-4 text-center" style={{ zIndex: 3 }}>
					<h3 className="para41 font-f4 font-sz-35" data-34 >{data.fennecJson.homePage.portfolio.data34}</h3>
					<button className="button41 font-f5 font-sz-16" onClick={() => onHide(true)}>GET IN TOUCH</button>
				</div>
				<div className="col-12 col-xl-4 d-none d-xl-block">
					<Img className="rightImg6" fluid={data.smallLamp.childImageSharp.fluid} />
				</div>
			</div>
			<div className="section5">
				<div className="row align-items-center justify-content-around section5row">
					<Fade left distance={'30%'}>
						<div className="col-12 col-lg-5 text-center text-lg-left">
							<a href="https://www.alpha-apps.ae/" target="_blank" rel="noopener noreferrer">
								<h3 className="stillNot font-f4 font-sz-44" data-35 >{data.fennecJson.homePage.portfolio.data35}</h3>
								<h3 className="checkOut font-more font-sz-20" data-36 >{data.fennecJson.homePage.portfolio.data36}</h3>
							</a>
						</div>
					</Fade>
					<Fade right distance={'30%'}>
						<div className="col-12 col-lg-5 text-center text-lg-left">
							<a href="https://www.alpha-apps.ae/" target="_blank" rel="noopener noreferrer">
								<img src={LogoBlack} className="footLogo" alt="" />
							</a>
						</div>
					</Fade>
				</div>
			</div>
		</Layout>
	)
}


export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    page1img1: file(relativePath: { eq: "page1img1.png" }) {
      ...fluidImage
    }
    page1img2: file(relativePath: { eq: "page1img2.png" }) {
      ...fluidImage
    }
    cashInvestmentImg: file(relativePath: { eq: "Cash_investment_1.png" }) {
      ...fluidImage
    }
    typoman: file(relativePath: { eq: "typoman.png" }) {
      ...fluidImage
    }
    snappCard: file(relativePath: { eq: "snappCard.png" }) {
      ...fluidImage
    }
    muslim: file(relativePath: { eq: "muslim.png" }) {
      ...fluidImage
    }
    falcon: file(relativePath: { eq: "falcon.png" }) {
      ...fluidImage
    }
    bigLamp: file(relativePath: { eq: "bigLamp.png" }) {
      ...fluidImage
    }
    smallLamp: file(relativePath: { eq: "smallLamp.png" }) {
      ...fluidImage
		}
		fennecJson {
      homePage {
        whatWeDo {
					data1,
					data2
				},
				techForEquity {
					data3,
					data4,
					data5,
					data6,
					data7,
					data8,
					data9,
					data10,
					data11,
					data12,
					data13,
					data14,
					data15,
					data16,
				},
				cashInvestment {
					data17,
					data18,
					data19,
					data21,
					data22,
					data23,
					data24,
					data25,
					data26,
					data27,
					data28,
					data29,
					data30,
				},
				portfolio {
					data31,
					data32,
					data33,
					data34,
					data35,
					data36,
				},
			}
    }
  }
`;
