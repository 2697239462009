import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import arrowDownPath from "../img/arrowDown.svg";
import arrowUpPath from "../img/arrowUp.svg";

export default ({currentSection}) => {

  const sections = {
    1: 'whatwedo',
    2: 'techforequity',
    3: 'cashinvestment',
    4: 'portfolio'
  }

  if(typeof document !== `undefined`){
    var arrowUp = document.getElementById("arrowUp");
    var arrowDown = document.getElementById("arrowDown");
  }

  if(currentSection !== 1){
    if(arrowUp){
      arrowUp.classList.add("fadeInDown");
      arrowUp.classList.remove("invisible");
    }
  }
  else{
    if(arrowUp){
      arrowUp.classList.remove("fadeInDown");
      arrowUp.classList.add("invisible");
    }
  }
  if(currentSection === 4){
    if(arrowDown){
      arrowDown.classList.add("fadeOutDown");
      arrowDown.classList.remove("fadeInUp");
    }
  }
  else{
    if(arrowDown){
      arrowDown.classList.remove("fadeOutDown");
      arrowDown.classList.add("fadeInUp");
    }
  }
  // const prevSection = currentSection !== 1? `/#section${currentSection-1}`: '/';
  // const nextSection = currentSection !== 4? `/#section${currentSection+1}`: '/#portfolio';

  const prevSection = currentSection !== 1? currentSection-1: 1;
  const nextSection = currentSection !== 4? currentSection+1: 4;

  
  useEffect(() => {
    if(typeof document !== `undefined`){
      var elem = document.getElementById('os');
    }
    if(elem){
      elem.classList.add("flipInY");
      setTimeout( function(){ 
        elem.classList.remove("flipInY");
      }  , 500 );
    }
  }, [currentSection]);

  
  return (
    <div className="paging">
      <Link to={`/#${sections[prevSection]}`} data-scroll><img src={arrowUpPath} alt="" className="arrowUp animated2 invisible" id="arrowUp"/></Link>
      <p className="num font-sz-18 font-f5 animated2" id="os">{`0${currentSection}`}</p>
      <p className="slash slash2 font-sz-22 font-f6">/</p>
      <p className="num font-sz-18 font-f5" id="os">04</p>
      <Link to={`/#${sections[nextSection]}`} data-scroll><img src={arrowDownPath} alt="" className="arrowDown animated2 fadeInUp" id="arrowDown" /></Link>
    </div>
  )
}
